import React from 'react';
import { string, arrayOf, bool, func, number } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import dropWhile from 'lodash/dropWhile';
import classNames from 'classnames';
import { Avatar, InlineTextButton, ReviewRating, UserDisplayName } from '../../components';
import { formatDate } from '../../util/dates';
import { ensureTransaction, ensureUser, ensureListing } from '../../util/data';
import {
  TRANSITION_ENQUIRE,
  TRANSITION_SET_PRICE,
  TRANSITION_UPDATE_PRICE,
  TRANSITION_REQUEST_PAYMENT,
  TRANSITION_CHARGE_CUSTOMER,
  TRANSITION_ACCEPT,
  TRANSITION_CANCEL,
  TRANSITION_COMPLETE,
  TRANSITION_OP_COMPLETE,
  TRANSITION_DECLINE,
  TRANSITION_PAYMENT_CAPTURE_FAIL,
  TRANSITION_EXPIRE_REQUEST_PERIOD,
  TRANSITION_CONFIRM_PAYMENT,
  TRANSITION_CONFIRM_PAYMENT_BY_OPERATOR,
  TRANSITION_REVIEW_1_BY_CUSTOMER,
  TRANSITION_OP_REVIEW_1_BY_CUSTOMER,
  TRANSITION_REVIEW_1_BY_PROVIDER,
  TRANSITION_OP_REVIEW_1_BY_PROVIDER,
  TRANSITION_REVIEW_2_BY_CUSTOMER,
  TRANSITION_OP_REVIEW_2_BY_CUSTOMER,
  TRANSITION_REVIEW_2_BY_PROVIDER,
  TRANSITION_OP_REVIEW_2_BY_PROVIDER,
  TRANSITION_COMPLETE_MANUAL,
  TRANSITION_SET_DELIVERED,
  TRANSITION_OP_SET_DELIVERED,
  TRANSITION_REQUEST_CHANGES,
  TRANSITION_OP_REQUEST_CHANGES,
  TRANSITION_MARK_DELIVERED_CHANGES,
  TRANSITION_OP_MARK_DELIVERED_CHANGES,
  TRANSITION_SKIP_1_BY_CUSTOMER,
  TRANSITION_SKIP_1_BY_PROVIDER,
  TRANSITION_SKIP_2_BY_CUSTOMER,
  TRANSITION_SKIP_2_BY_PROVIDER,
  TRANSITION_SKIP_3_BY_CUSTOMER,
  TRANSITION_SKIP_3_BY_PROVIDER,
  TRANSITION_REQUEST_EXTEND_DELIVERY_PERIOD,
  TRANSITION_OP_REQUEST_EXTEND_DELIVERY_PERIOD,
  TRANSITION_ACCEPT_EXTEND_DELIVERY_PERIOD,
  TRANSITION_OP_ACCEPT_EXTEND_DELIVERY_PERIOD,
  TRANSITION_AUTO_REQUEST_EXTEND_DELIVERY_PERIOD,
  TRANSITION_DECLINE_EXTEND_DELIVERY_PERIOD,
  TRANSITION_OP_DECLINE_EXTEND_DELIVERY_PERIOD,
  TRANSITION_EXPIRED_FROM_ACCEPTED_1,
  TRANSITION_EXPIRED_FROM_DELIVERED_1,
  transitionIsReviewed,
  txIsInFirstReviewBy,
  txIsReviewed,
  isCustomerReview,
  isProviderReview,
  txRoleIsProvider,
  txRoleIsCustomer,
  getUserTxRole,
  isRelevantPastTransition,
  TRANSITION_EXPIRE_CUSTOMER_REVIEW_PERIOD,
  TRANSITION_EXPIRE_PROVIDER_REVIEW_PERIOD,
  TRANSITION_OP_CANCEL_ORDER,
  TRANSITION_OP_CANCEL_ORDER_2,
} from '../../util/transaction';
import { propTypes } from '../../util/types';
import * as log from '../../util/log';

import css from './ActivityFeed.css';
import config from '../../config';

const Message = props => {
  const { message, intl } = props;
  const todayString = intl.formatMessage({ id: 'ActivityFeed.today' });
  return (
    <div className={css.message}>
      <Avatar className={css.avatar} user={message.sender} disableProfileLink />
      <div>
        <p className={css.messageContent}>{message.attributes.content}</p>
        <p className={css.messageDate}>
          {formatDate(intl, todayString, message.attributes.createdAt)}
        </p>
      </div>
    </div>
  );
};

Message.propTypes = {
  message: propTypes.message.isRequired,
  intl: intlShape.isRequired,
};

const OwnMessage = props => {
  const { message, intl } = props;
  const todayString = intl.formatMessage({ id: 'ActivityFeed.today' });
  return (
    <div className={css.ownMessage}>
      <div className={css.ownMessageContentWrapper}>
        <p className={css.ownMessageContent}>{message.attributes.content}</p>
      </div>
      <p className={css.ownMessageDate}>
        {formatDate(intl, todayString, message.attributes.createdAt)}
      </p>
    </div>
  );
};

OwnMessage.propTypes = {
  message: propTypes.message.isRequired,
  intl: intlShape.isRequired,
};

const Review = props => {
  const { content, rating } = props;
  return (
    <div>
      <p className={css.reviewContent}>{content !== config.dummyReviewContent && content}</p>
      <ReviewRating
        reviewStarClassName={css.reviewStar}
        className={css.reviewStars}
        rating={rating}
      />
    </div>
  );
};

Review.propTypes = {
  content: string.isRequired,
  rating: number.isRequired,
};

const transitionIcon = transition => {
  switch (transition) {
    case TRANSITION_DECLINE:
    case TRANSITION_CANCEL:
    case TRANSITION_OP_CANCEL_ORDER:
    case TRANSITION_OP_CANCEL_ORDER_2:
    case TRANSITION_DECLINE_EXTEND_DELIVERY_PERIOD:
    case TRANSITION_EXPIRED_FROM_ACCEPTED_1:
      return (
        <svg
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M19.9987 36.6673C29.2034 36.6673 36.6654 29.2054 36.6654 20.0007C36.6654 10.7959 29.2034 3.33398 19.9987 3.33398C10.794 3.33398 3.33203 10.7959 3.33203 20.0007C3.33203 29.2054 10.794 36.6673 19.9987 36.6673Z"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M25 15L15 25"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M15 15L25 25"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      );
    default:
      return (
        <svg
          width="37"
          height="35"
          viewBox="0 0 37 35"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M35.6483 15.7643V17.2593C35.6462 20.7635 34.4698 24.1731 32.2945 26.9798C30.1192 29.7864 27.0615 31.8396 23.5776 32.8331C20.0936 33.8267 16.37 33.7074 12.9621 32.493C9.55421 31.2786 6.64459 29.0342 4.6672 26.0945C2.68981 23.1549 1.7506 19.6774 1.98965 16.1808C2.22869 12.6842 3.63318 9.35583 5.99364 6.69204C8.35411 4.02826 11.5441 2.1718 15.0878 1.39954C18.6315 0.627279 22.3391 0.980599 25.6577 2.4068"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M35.6479 4.25977L18.8004 20.526L13.7461 15.651"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      );
  }
};

const hasUserLeftAReviewFirst = (userRole, transaction) => {
  // Because function txIsInFirstReviewBy uses isCustomer to check in which state the reviews are
  // we should also use isCustomer instead of isProvider
  const isCustomer = txRoleIsCustomer(userRole);
  return txIsInFirstReviewBy(transaction, isCustomer);
};

const resolveTransitionMessage = (
  transaction,
  transition,
  listingTitle,
  ownRole,
  otherUsersName,
  intl,
  onOpenReviewModal
) => {
  const isOwnTransition = transition.by === ownRole;
  const currentTransition = transition.transition;
  const displayName = otherUsersName;

  switch (currentTransition) {
    case TRANSITION_ENQUIRE:
      return isOwnTransition ? (
        <FormattedMessage id="ActivityFeed.ownTransitionEnquire" />
      ) : (
        <FormattedMessage id="ActivityFeed.transitionEnquire" />
      );
    case TRANSITION_SET_PRICE:
      return isOwnTransition ? (
        <FormattedMessage id="ActivityFeed.ownTransitionPriceOffered" />
      ) : (
        <FormattedMessage id="ActivityFeed.transitionPriceOffered" />
      );
    case TRANSITION_UPDATE_PRICE:
      return isOwnTransition ? (
        <FormattedMessage id="ActivityFeed.ownTransitionPriceUpdated" />
      ) : (
        <FormattedMessage id="ActivityFeed.transitionPriceUpdated" />
      );
    case TRANSITION_REQUEST_PAYMENT:
      return isOwnTransition ? (
        <FormattedMessage id="ActivityFeed.ownTransitionRequestPayment" values={{ listingTitle }} />
      ) : (
        <FormattedMessage
          id="ActivityFeed.transitionRequestPayment"
          values={{ displayName, listingTitle }}
        />
      );
    case TRANSITION_CONFIRM_PAYMENT:
      return isOwnTransition ? (
        <FormattedMessage id="ActivityFeed.ownTransitionPaymentConfirmed" values={{ listingTitle }} />
      ) : (
        <FormattedMessage
          id="ActivityFeed.transitionPaymentConfirmed"
          values={{ displayName, listingTitle }}
        />
      );
    case TRANSITION_CONFIRM_PAYMENT_BY_OPERATOR:
      return isOwnTransition ? (
        <FormattedMessage id="ActivityFeed.ownTransitionPaymentConfirmed" values={{ listingTitle }} />
      ) : (
        <FormattedMessage
          id="ActivityFeed.transitionRequest"
          values={{ displayName, listingTitle }}
        />
      );
    case TRANSITION_CHARGE_CUSTOMER:
      return isOwnTransition ? (
        <FormattedMessage id="ActivityFeed.ownTransitionRequest" />
      ) : (
        <FormattedMessage id="ActivityFeed.transitionRequest" />
      );
    case TRANSITION_ACCEPT:
      return isOwnTransition ? (
        <FormattedMessage id="ActivityFeed.ownTransitionAccept" />
      ) : (
        <FormattedMessage id="ActivityFeed.transitionAccept" />
      );
    case TRANSITION_REQUEST_EXTEND_DELIVERY_PERIOD:
    case TRANSITION_OP_REQUEST_EXTEND_DELIVERY_PERIOD:
      return isOwnTransition ? (
        <FormattedMessage id="ActivityFeed.ownTransitionExtendRequest" />
      ) : (
        <FormattedMessage id="ActivityFeed.transitionExtendRequest" />
      );
    case TRANSITION_ACCEPT_EXTEND_DELIVERY_PERIOD:
    case TRANSITION_OP_ACCEPT_EXTEND_DELIVERY_PERIOD:
      return isOwnTransition ? (
        <FormattedMessage id="ActivityFeed.ownTransitionExtendAccept" />
      ) : (
        <FormattedMessage id="ActivityFeed.transitionExtendAccept" />
      );
    case TRANSITION_AUTO_REQUEST_EXTEND_DELIVERY_PERIOD:
      return isOwnTransition ? (
        <FormattedMessage id="ActivityFeed.ownTransitionAutoExtendAccept" />
      ) : (
        <FormattedMessage id="ActivityFeed.transitionAutoExtendAccept" />
      );
    case TRANSITION_DECLINE:
      return isOwnTransition ? (
        <FormattedMessage id="ActivityFeed.ownTransitionDecline" />
      ) : (
        <FormattedMessage id="ActivityFeed.transitionDecline" values={{ displayName }} />
      );
    case TRANSITION_EXPIRE_REQUEST_PERIOD:
      return txRoleIsProvider(ownRole) ? (
        <FormattedMessage id="ActivityFeed.ownTransitionExpireRequestPeriod" />
      ) : (
        <FormattedMessage id="ActivityFeed.transitionExpireRequestPeriod" />
      );
    case TRANSITION_PAYMENT_CAPTURE_FAIL:
      return <FormattedMessage id="ActivityFeed.transitionPaymentCaptureFailed" />
    case TRANSITION_CANCEL:
    case TRANSITION_OP_CANCEL_ORDER:
    case TRANSITION_EXPIRED_FROM_ACCEPTED_1:
      return <FormattedMessage id="ActivityFeed.transitionCancel" />;
    case TRANSITION_DECLINE_EXTEND_DELIVERY_PERIOD:
    case TRANSITION_OP_DECLINE_EXTEND_DELIVERY_PERIOD:
      return <FormattedMessage id="ActivityFeed.transitionExtendDecline" />;
    case TRANSITION_SET_DELIVERED:
    case TRANSITION_OP_SET_DELIVERED:
      return <FormattedMessage id="ActivityFeed.transitionSetDelivered" />;
    case TRANSITION_REQUEST_CHANGES:
    case TRANSITION_OP_REQUEST_CHANGES:
      return <FormattedMessage id="ActivityFeed.requestChanges" />;
    case TRANSITION_MARK_DELIVERED_CHANGES:
    case TRANSITION_OP_MARK_DELIVERED_CHANGES:
      return <FormattedMessage id="ActivityFeed.updatedDelivery" />;

    case TRANSITION_COMPLETE:
    case TRANSITION_OP_COMPLETE:
      const reviewPeriodIsOver = txIsReviewed(transaction);
      const userHasLeftAReview = hasUserLeftAReviewFirst(ownRole, transaction);
      const otherUserHasLeftAReview = hasUserLeftAReviewFirst(
        ownRole === 'customer' ? 'provider' : 'customer',
        transaction
      );

      return (
        <FormattedMessage
          id="ActivityFeed.collaborationEnded"
          values={{
            reviewLink: !(reviewPeriodIsOver || userHasLeftAReview || otherUserHasLeftAReview) && (
              <InlineTextButton onClick={onOpenReviewModal}>
                <FormattedMessage id="ActivityFeed.leaveAReview" values={{ displayName }} />
              </InlineTextButton>
            ),
          }}
        />
      );
    case TRANSITION_COMPLETE_MANUAL:
      return <FormattedMessage id="ActivityFeed.collaborationEnded" />;
    case TRANSITION_EXPIRED_FROM_DELIVERED_1:
      return <FormattedMessage id="ActivityFeed.expiredFromDelivered" />;

    case TRANSITION_REVIEW_1_BY_PROVIDER:
    case TRANSITION_OP_REVIEW_1_BY_PROVIDER:
      if (isOwnTransition) {
        return <FormattedMessage id="ActivityFeed.ownTransitionReview" values={{ displayName }} />;
      } else {
        // show the leave a review link if current user is not the first
        // one to leave a review
        const reviewPeriodIsOver = txIsReviewed(transaction);
        const userHasLeftAReview = hasUserLeftAReviewFirst(ownRole, transaction);
        const reviewAsSecondLink = !(reviewPeriodIsOver || userHasLeftAReview) ? (
          <InlineTextButton onClick={onOpenReviewModal}>
            <FormattedMessage id="ActivityFeed.leaveAReviewSecond" values={{ displayName }} />
          </InlineTextButton>
        ) : null;
        return (
          <FormattedMessage
            id="ActivityFeed.transitionReview"
            values={{ displayName, reviewLink: reviewAsSecondLink }}
          />
        );
      }
    case TRANSITION_REVIEW_1_BY_CUSTOMER:
    case TRANSITION_OP_REVIEW_1_BY_CUSTOMER:
      if (isOwnTransition) {
        return <FormattedMessage id="ActivityFeed.ownTransitionReview" values={{ displayName }} />;
      } else {
        // show the leave a review link if current user is not the first
        // one to leave a review
        const reviewPeriodIsOver = txIsReviewed(transaction);
        const userHasLeftAReview = hasUserLeftAReviewFirst(ownRole, transaction);
        const reviewAsSecondLink = !(reviewPeriodIsOver || userHasLeftAReview) ? (
          <InlineTextButton onClick={onOpenReviewModal}>
            <FormattedMessage id="ActivityFeed.leaveAReviewSecond" values={{ displayName }} />
          </InlineTextButton>
        ) : null;
        return (
          <FormattedMessage
            id="ActivityFeed.transitionReview"
            values={{ displayName, reviewLink: reviewAsSecondLink }}
          />
        );
      }
    case TRANSITION_REVIEW_2_BY_PROVIDER:
    case TRANSITION_OP_REVIEW_2_BY_PROVIDER:
    case TRANSITION_REVIEW_2_BY_CUSTOMER:
    case TRANSITION_OP_REVIEW_2_BY_CUSTOMER:
      if (isOwnTransition) {
        return <FormattedMessage id="ActivityFeed.ownTransitionReview" values={{ displayName }} />;
      } else {
        return (
          <FormattedMessage
            id="ActivityFeed.transitionReview"
            values={{ displayName, reviewLink: null }}
          />
        );
      }
    case TRANSITION_EXPIRE_CUSTOMER_REVIEW_PERIOD:
      return <FormattedMessage id="ActivityFeed.expireCustomerReviewPeriod" />;

    case TRANSITION_EXPIRE_PROVIDER_REVIEW_PERIOD:
      return <FormattedMessage id="ActivityFeed.expireProviderReviewPeriod" />;

    case TRANSITION_SKIP_1_BY_CUSTOMER:
    case TRANSITION_SKIP_1_BY_PROVIDER:
      if (!isOwnTransition) {
        return (
          <InlineTextButton onClick={onOpenReviewModal}>
            <FormattedMessage id="ActivityFeed.leaveAReview" values={{ displayName }} />
          </InlineTextButton>
        );
      }
      return <FormattedMessage id="ActivityFeed.skippedAReview" values={{ displayName }} />;

    case TRANSITION_SKIP_2_BY_CUSTOMER:
    case TRANSITION_SKIP_2_BY_PROVIDER:
    case TRANSITION_SKIP_3_BY_CUSTOMER:
    case TRANSITION_SKIP_3_BY_PROVIDER:
      return;

    default:
      log.error(new Error('Unknown transaction transition type'), 'unknown-transition-type', {
        transitionType: currentTransition,
      });
      return '';
  }
};

const reviewByAuthorId = (transaction, userId) => {
  return transaction.reviews.filter(r => r.author.id.uuid === userId.uuid)[0];
};

const Transition = props => {
  const { transition, transaction, currentUser, intl, onOpenReviewModal } = props;

  const currentTransaction = ensureTransaction(transaction);
  const customer = currentTransaction.customer;
  const provider = currentTransaction.provider;

  const deletedListing = intl.formatMessage({
    id: 'ActivityFeed.deletedListing',
  });
  const listingTitle = currentTransaction.listing.attributes.deleted
    ? deletedListing
    : currentTransaction.listing.attributes.title;
  const lastTransition = currentTransaction.attributes.lastTransition;

  const ownRole = getUserTxRole(currentUser.id, currentTransaction);

  const otherUsersName = txRoleIsProvider(ownRole) ? (
    <UserDisplayName user={customer} intl={intl} />
  ) : (
    <UserDisplayName user={provider} intl={intl} />
  );

  const transitionMessage = resolveTransitionMessage(
    transaction,
    transition,
    listingTitle,
    ownRole,
    otherUsersName,
    intl,
    onOpenReviewModal
  );
  const currentTransition = transition.transition;

  let reviewComponent = null;

  if (transitionIsReviewed(lastTransition)) {
    if (isCustomerReview(currentTransition)) {
      const review = reviewByAuthorId(currentTransaction, customer.id);
      reviewComponent = (
        <Review content={review.attributes.content} rating={review.attributes.rating} />
      );
    } else if (isProviderReview(currentTransition)) {
      const review = reviewByAuthorId(currentTransaction, provider.id);
      reviewComponent = (
        <Review content={review.attributes.content} rating={review.attributes.rating} />
      );
    }
  }

  const { workflowStatus, workflowEvents = [] } = transaction.attributes.metadata;
  const deliveredDate = new Date(workflowEvents.find(e => e?.type === 'delivered')?.time);
  const isDelivered = currentTransition === TRANSITION_CONFIRM_PAYMENT && workflowStatus === 'delivered';

  const todayString = intl.formatMessage({ id: 'ActivityFeed.today' });

  return (
    <>
      <div className={css.transition}>
        <div className={css.bullet}>{transitionIcon(currentTransition)}</div>
        <div>
          <p className={css.transitionContent}>{transitionMessage}</p>
          <p className={css.transitionDate}>{formatDate(intl, todayString, transition.createdAt)}</p>
          {reviewComponent}
        </div>
      </div>
      {isDelivered && <div className={css.transitionDelivered}>
        <div className={css.bullet}>{transitionIcon(currentTransition)}</div>
        <div>
          <p className={css.transitionContent}>Delivered</p>
          <p className={css.transitionDate}>{formatDate(intl, todayString, deliveredDate)}</p>
          {reviewComponent}
        </div>
      </div>}
    </>
  );
};

Transition.propTypes = {
  transition: propTypes.transition.isRequired,
  transaction: propTypes.transaction.isRequired,
  currentUser: propTypes.currentUser.isRequired,
  intl: intlShape.isRequired,
  onOpenReviewModal: func.isRequired,
};

const EmptyTransition = () => {
  return (
    <div className={css.transition}>
      <div className={css.bullet}>
        <p className={css.transitionContent}>•</p>
      </div>
      <div>
        <p className={css.transitionContent} />
        <p className={css.transitionDate} />
      </div>
    </div>
  );
};

const isMessage = item => item && item.type === 'message';

// Compare function for sorting an array containing messages and transitions
const compareItems = (a, b) => {
  const itemDate = item => (isMessage(item) ? item.attributes.createdAt : item.createdAt);
  return itemDate(a) - itemDate(b);
};

const organizedItems = (messages, transitions, hideOldTransitions) => {
  const items = messages.concat(transitions).sort(compareItems);
  if (hideOldTransitions) {
    // Hide transitions that happened before the oldest message. Since
    // we have older items (messages) that we are not showing, seeing
    // old transitions would be confusing.
    return dropWhile(items, i => !isMessage(i));
  } else {
    return items;
  }
};

export const ActivityFeedComponent = props => {
  const {
    rootClassName,
    className,
    messages,
    transaction,
    currentUser,
    hasOlderMessages,
    onOpenReviewModal,
    onShowOlderMessages,
    fetchMessagesInProgress,
    intl,
  } = props;

  const classes = classNames(rootClassName || css.root, className);

  const currentTransaction = ensureTransaction(transaction);
  const transitions = currentTransaction.attributes.transitions
    ? currentTransaction.attributes.transitions
    : [];
      const currentCustomer = ensureUser(currentTransaction.customer);
  const currentProvider = ensureUser(currentTransaction.provider);
  const currentListing = ensureListing(currentTransaction.listing);

  const transitionsAvailable = !!(
    currentUser &&
    currentUser.id &&
    currentCustomer.id &&
    currentProvider.id &&
    currentListing.id
  );

  // combine messages and transaction transitions
  const items = organizedItems(messages, transitions, hasOlderMessages || fetchMessagesInProgress);

  const transitionComponent = transition => {
    if (transitionsAvailable) {
      return (
        <Transition
          transition={transition}
          transaction={transaction}
          currentUser={currentUser}
          intl={intl}
          onOpenReviewModal={onOpenReviewModal}
        />
      );
    } else {
      return <EmptyTransition />;
    }
  };

  const messageComponent = message => {
    const isOwnMessage =
      message.sender &&
      message.sender.id &&
      currentUser &&
      currentUser.id &&
      message.sender.id.uuid === currentUser.id.uuid;
    if (isOwnMessage) {
      return <OwnMessage message={message} intl={intl} />;
    }
    return <Message message={message} intl={intl} />;
  };

  const messageListItem = message => {
    return (
      <li id={`msg-${message.id.uuid}`} key={message.id.uuid} className={css.messageItem}>
        {messageComponent(message)}
      </li>
    );
  };

  const transitionListItem = transition => {
    if (isRelevantPastTransition(transition.transition)) {
      return (
        <li key={transition.transition} className={css.transitionItem}>
          {transitionComponent(transition)}
        </li>
      );
    } else {
      return null;
    }
  };

  const itemsList = items.map(item => {
    const finalMessage = isMessage(item) ? messageListItem(item) : transitionListItem(item);

    return finalMessage;
  });

  return (
    <ul className={classes}>
      {hasOlderMessages ? (
        <li className={css.showOlderWrapper} key="show-older-messages">
          <InlineTextButton className={css.showOlderButton} onClick={onShowOlderMessages}>
            <FormattedMessage id="ActivityFeed.showOlderMessages" />
          </InlineTextButton>
        </li>
      ) : null}

      {/* filter because for some reason the 'null' is returned as a transition timestamp */}
      {itemsList.filter(i => i)}
    </ul>
  );
};

ActivityFeedComponent.defaultProps = {
  rootClassName: null,
  className: null,
};

ActivityFeedComponent.propTypes = {
  rootClassName: string,
  className: string,

  currentUser: propTypes.currentUser,
  transaction: propTypes.transaction,
  messages: arrayOf(propTypes.message),
  hasOlderMessages: bool.isRequired,
  onOpenReviewModal: func.isRequired,
  onShowOlderMessages: func.isRequired,
  fetchMessagesInProgress: bool.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const ActivityFeed = injectIntl(ActivityFeedComponent);

export default ActivityFeed;
