import React, { forwardRef, useState, useImperativeHandle, useRef } from 'react';
import { string, bool, node } from 'prop-types';
import moment from 'moment';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import classNames from 'classnames';
import {
  Form,
  PrimaryButton,
  SecondaryButton,
  FieldSelect,
  FieldTextInput,
  FieldCountNumber,
  LocationAutocompleteInputField,
  HeadingFormFieldWrapper,
} from '../../components';
import { Modal } from '@mui/material';
import { Box } from '@material-ui/core';
import * as validators from '../../util/validators';
import config from '../../config';
import { currencyConfiguration } from '../../currency-config';
import { isAfterDate, subtractTime } from 'util/dates';
import {
  convertMoneyTypeToNumber,
} from 'util/currency'
import { currencyDB } from 'lib/currency-list';

import css from './CheckoutTripForm.css';

const textRequired = value => (value ? undefined : 'Required');
const identity = v => v;

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: 16,
  py: 3,
  px: 4,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
};

const getStandardTime = (items, participants) => {
  if (items >= 31 || participants >= 31) return 120;
  else if ((items >= 21 && items <= 30) || (participants >= 16 && participants <= 30)) return 96;
  else if ((items >= 9 && items <= 20) || (participants >= 7 && participants <= 15)) return 72;
  else if (items <= 8 || participants <= 6) return 48;
  else return 48;
};

function createSentence(words) {
  const len = words.length;

  if (len === 0) {
    return ''; // Return an empty string for an empty list
  } else if (len === 1) {
    return words[0]; // Return the only word as it is for a list with a single word
  } else {
    // Insert ', ' between all words except the last two
    const sentence = words.slice(0, len - 2).join(', ') + ', ';

    // Add ' and ' between the last two words and append the last word
    return sentence + words.slice(len - 2).join(' and ');
  }
}

const CheckoutTripFormComponent = forwardRef((props, ref) => {
  const formRef = useRef(null);

  // To check if the prefilling is valid
  const { lastTransaction } = props;
  const tripDataValues = JSON.parse(localStorage.getItem('tripDetails'));
  const isPrefillValid = !tripDataValues?.transportType && !tripDataValues?.accommodationType && lastTransaction
    ? isAfterDate(lastTransaction.attributes.createdAt, subtractTime(new Date(), 7, 'days'))
    : false;
  const tripDetails = lastTransaction?.attributes?.protectedData?.tripDetails || {};

  const {
    tripName,
    tripType = 'Open to Anything',
    estimatedMinBudget,
    estimatedMaxBudget,
    budgetCurrency = 'USD',
    tripLocation,
    precision,
    originLocation,
    targetStartDate,
    targetEndDate,
    timePrecision,
    numberOfAdults,
    numberOfChildren,
    numberOfInfants,
    includeTransport,
    transportType,
    transportPricingType,
    includeAccommodation,
    accommodationType,
    includeDineInMeals,
    numberOfBreakfast,
    breakfastVenueType,
    numberOfLunch,
    lunchVenueType,
    numberOfDinner,
    dinnerVenueType,
    mealPricing,
    includeActivities,
    researchActivities,
    guidance,
  } = tripDetails;

  const currencySign = currencyDB.find(i => i.value === budgetCurrency)?.sign;

  const lastNumberOfAdults = numberOfAdults;
  const lastNumberOfChildren = numberOfChildren;
  const lastNumberOfInfants = numberOfInfants;

   // To get conditional variables
   const transportInclude =
   includeTransport && includeTransport.includes('yes') && (transportType || accommodationType);
 const accommodationInclude = includeAccommodation && includeAccommodation.includes('yes');
 const mealsInclude =
   includeDineInMeals &&
   includeDineInMeals.includes('yes') &&
   (numberOfBreakfast || numberOfLunch || numberOfDinner);

 const activitiesInclude = includeActivities && includeActivities.includes('yes');

  const [showPrefillModal, setShowPrefillModal] = useState(isPrefillValid);

  const closeModal = () => {
    setShowPrefillModal(false);
  };

  useImperativeHandle(ref, () => ({
    submitForm: () => {
      if (formRef.current) {
        formRef.current()
      }
    }
  }));

  return (
    <FinalForm
      {...props}
      render={fieldRenderProps => {
        const {
          rootClassName,
          className,
          submitButtonWrapperClassName,
          handleSubmit,
          onChange,
          inProgress,
          intl,
          listing,
          values,
          onBack,
          form,
        } = fieldRenderProps;

        formRef.current = handleSubmit;

        const messageRequiredMessage = intl.formatMessage({
          id: 'EnquiryForm.messageRequired',
        });
        const messageRequired = validators.requiredAndNonEmptyString(messageRequiredMessage);

        // Participants
        const numberOfAdults = values.numberOfAdults ?? 0;
        const numberOfChildren = values.numberOfChildren ?? 0;
        const numberOfInfants = values.numberOfInfants ?? 0;
        const numberOfParticipants = numberOfAdults + numberOfChildren + numberOfInfants;

        // Items
        const coefficientOfTransport = values.transportType == 'All' ? 2 : 1;
        const numberOfTransport =
          JSON.stringify(values.includeTransport) == JSON.stringify(['yes']) &&
            values?.transportType
            ? coefficientOfTransport * 2
            : 0;
        const numberOfAccommodation =
          JSON.stringify(values.includeAccommodation) == JSON.stringify(['yes']) &&
            values?.accommodationType
            ? 1
            : 0;
        const numberOfBreakfast = values.numberOfBreakfast ?? 0;
        const numberOfLunch = values.numberOfLunch ?? 0;
        const numberOfDinner = values.numberOfDinner ?? 0;
        const numberOfDine =
          JSON.stringify(values.includeDineInMeals) == JSON.stringify(['yes'])
            ? numberOfBreakfast + numberOfLunch + numberOfDinner
            : 0;
        const numberOfActivities =
          JSON.stringify(values.includeActivities) == JSON.stringify(['yes'])
            ? values.researchActivities?.length ?? 0
            : 0;
        const numberOfItems =
          numberOfTransport + numberOfAccommodation + numberOfDine + numberOfActivities;
        const wordList = [];

        const standardTime = getStandardTime(numberOfItems, numberOfParticipants);

        if (lastNumberOfAdults || lastNumberOfChildren || lastNumberOfInfants) {
          wordList.push('Who');
        }

        if (transportInclude && transportType) {
          wordList.push('Transport');
        }

        if (accommodationInclude && accommodationType) {
          wordList.push('Accommodation');
        }

        if (mealsInclude) {
          wordList.push('Meals');
        }

        if (activitiesInclude && researchActivities) {
          wordList.push('Activity');
        }

        if (guidance) {
          wordList.push('Guidance Information');
        }

        const sentence = createSentence(wordList);

        //Living In
        const contextNote = intl.formatMessage({
          id: 'CheckoutTripForm.contextNote',
        });

        const tripLocationLabel = intl.formatMessage({
          id: 'CheckoutTripForm.tripLocationLabel',
        });
        const originLocationLabel = intl.formatMessage({
          id: 'CheckoutTripForm.originLocationLabel',
        });
        const addressRequiredMessage = intl.formatMessage({
          id: 'CheckoutTripForm.addressRequired',
        });
        const addressNotRecognizedMessage = intl.formatMessage({
          id: 'CheckoutTripForm.addressNotRecognized',
        });

        //Trip
        const tripLabel = intl.formatMessage({
          id: 'CheckoutTripForm.tripLabel',
        });
        const tripSupportMessage = intl.formatMessage({
          id: 'CheckoutTripForm.tripSupport',
        });

        const tripNameLabel = intl.formatMessage({
          id: 'CheckoutTripForm.tripNameLabel',
        });

        const tripTypeLabel = intl.formatMessage({
          id: 'CheckoutTripForm.tripTypeLabel',
        });

        const tripTypeRequiredMessage = intl.formatMessage({
          id: 'CheckoutTripForm.tripTypeRequired',
        });

        //Who
        const whoLabel = intl.formatMessage({
          id: 'CheckoutDetailsForm.whoLabel',
        });
        const whoSupportMessage = intl.formatMessage({
          id: 'CheckoutDetailsForm.whoSupport',
        });
        const numberAdultsLabel = intl.formatMessage({
          id: 'CheckoutDetailsForm.numberAdultsLabel',
        });
        const numberChildrenLabel = intl.formatMessage({
          id: 'CheckoutDetailsForm.numberChildrenLabel',
        });
        const numberInfantsLabel = intl.formatMessage({
          id: 'CheckoutDetailsForm.numberInfantsLabel',
        });

        //Where
        const whereLabel = intl.formatMessage({
          id: 'CheckoutTripForm.whereLabel',
        });
        const whereSupportMessage = intl.formatMessage({
          id: 'CheckoutTripForm.whereSupport',
        });

        //Precision
        const precisionLabel = intl.formatMessage({
          id: 'CheckoutTripForm.precisionLabel',
        });
        //Timing Precision
        const timingPrecisionLabel = intl.formatMessage({
          id: 'CheckoutTripForm.timingPrecisionLabel',
        });
        const precisionRequiredMessage = intl.formatMessage({
          id: 'CheckoutTripForm.precisionRequired',
        });

        //When
        const whenLabel = intl.formatMessage({
          id: 'CheckoutTripForm.whenLabel',
        });
        const whenSupportMessage = intl.formatMessage({
          id: 'CheckoutTripForm.whenSupport',
        });

        //Target Start Date
        const targetStartDateLabel = intl.formatMessage({
          id: 'CheckoutTripForm.targetStartDateLabel',
        });

        //Target End Date
        const targetEndDateLabel = intl.formatMessage({
          id: 'CheckoutTripForm.targetEndDateLabel',
        });

        const classes = classNames(rootClassName || css.root, className);
        const submitInProgress = inProgress;
        const submitDisabled = submitInProgress;

        return (
          <Form className={classes} onSubmit={handleSubmit}>
            <FormSpy onChange={onChange} />
            {contextNote}
            <HeadingFormFieldWrapper
              label={tripLabel}
              supportText={tripSupportMessage}
              className={css.sectionTitle}
            />

            <FieldTextInput
              id="tripName"
              name="tripName"
              className={css.field}
              type="text"
              label={tripNameLabel}
              placeholder={tripNameLabel}
              validate={textRequired}
            />

            <FieldSelect
              className={css.field}
              type="select"
              name="tripType"
              id="tripType"
              label={tripTypeLabel}
              validate={validators.required(tripTypeRequiredMessage)}
            >
              <option value="" disabled>
                Select Trip Type
              </option>
              <option value="Open to Anything">Open to Anything</option>
              <option value="Learn & Explore">Learn & Explore</option>
              <option value="Relax & Recharge">Relax & Recharge</option>
              <option value="Escape & Reflect">Escape & Reflect</option>
              <option value="Have an Adventure">Have an Adventure</option>
              <option value="Celebration Event">Celebration Event</option>
              <option value="Socialise & Experience">Socialise & Experience</option>
              <option value="Work">Work</option>
              <option value="Visit Someone">Visit Someone</option>
              <option value="Family Getaway">Family Getaway</option>
            </FieldSelect>

            <HeadingFormFieldWrapper
              label={whoLabel}
              supportText={whoSupportMessage}
              className={css.sectionTitle}
            />
            <FieldCountNumber
              id="numberOfAdults"
              type="number"
              name="numberOfAdults"
              className={css.field}
              defaultValue={1}
              form={form}
              label={numberAdultsLabel}
            />

            <FieldCountNumber
              id="numberOfChildren"
              type="number"
              name="numberOfChildren"
              className={css.field}
              form={form}
              label={numberChildrenLabel}
            />

            <FieldCountNumber
              id="numberOfInfants"
              type="number"
              name="numberOfInfants"
              className={css.field}
              form={form}
              label={numberInfantsLabel}
            />

            <HeadingFormFieldWrapper
              label={whereLabel}
              supportText={whereSupportMessage}
              className={css.sectionTitle}
            />

            <LocationAutocompleteInputField
              className={css.locationAddress}
              inputClassName={css.locationAutocompleteInput}
              iconClassName={css.locationAutocompleteInputIcon}
              predictionsClassName={css.desktopPredictions}
              validClassName={css.validLocation}
              autoFocus
              name="tripLocation"
              label={tripLocationLabel}
              placeholder={tripLocationLabel}
              useDefaultPredictions={false}
              format={identity}
              locationTypes={['locality', 'district', 'place', 'country', 'region', 'country']}
              valueFromForm={values.tripLocation}
              validate={validators.composeValidators(
                validators.autocompleteSearchRequired(addressRequiredMessage),
                validators.autocompletePlaceSelected(addressNotRecognizedMessage)
              )}
            />

            <FieldSelect
              className={css.field}
              type="select"
              name="precision"
              id="precision"
              label={precisionLabel}
              validate={validators.required(precisionRequiredMessage)}
            >
              <option value="" disabled>
                {'Select Geographic Precision'}
              </option>
              <option value="continent">{'Around Continent'}</option>
              <option value="country">{'Around Country'}</option>
              <option value="place">{'Around Place/City/State/Town'}</option>
            </FieldSelect>

            <LocationAutocompleteInputField
              className={css.locationAddress}
              inputClassName={css.locationAutocompleteInput}
              iconClassName={css.locationAutocompleteInputIcon}
              predictionsClassName={css.desktopPredictions}
              validClassName={css.validLocation}
              autoFocus
              name="originLocation"
              label={originLocationLabel}
              placeholder={originLocationLabel}
              useDefaultPredictions={false}
              format={identity}
              locationTypes={['locality', 'district', 'place']}
              valueFromForm={values.originLocation}
              validate={validators.composeValidators(
                validators.autocompleteSearchRequired(addressRequiredMessage),
                validators.autocompletePlaceSelected(addressNotRecognizedMessage)
              )}
            />

            <HeadingFormFieldWrapper
              label={whenLabel}
              supportText={whenSupportMessage}
              className={css.sectionTitle}
            />

            <FieldSelect
              className={css.field}
              type="select"
              name="timePrecision"
              id="timePrecision"
              label={timingPrecisionLabel}
              validate={validators.required(precisionRequiredMessage)}
            >
              <option value="" disabled>
                {'Select Timing Precision'}
              </option>
              <option value="Exactly">{'Exactly'}</option>
              <option value="Few days either side">{'Few days either side'}</option>
              <option value="Totally Flexible">{'Totally Flexible'}</option>
            </FieldSelect>

            <FieldTextInput
              id="targetStartDate"
              name="targetStartDate"
              className={css.field}
              type="date"
              label={targetStartDateLabel}
              placeholder={targetStartDateLabel}
              min={moment().format('YYYY-MM-DD')}
              validate={textRequired}
            />

            <FieldTextInput
              id="targetEndDate"
              name="targetEndDate"
              className={css.field}
              type="date"
              label={targetEndDateLabel}
              placeholder={targetEndDateLabel}
              min={values.targetStartDate || moment().format('YYYY-MM-DD')}
              validate={textRequired}
            />

            <div className={submitButtonWrapperClassName}>
              <SecondaryButton type="button" onClick={onBack}>
                <FormattedMessage id="CheckoutTripForm.back" />
              </SecondaryButton>

              <PrimaryButton type="submit" inProgress={submitInProgress} disabled={submitDisabled}>
                <FormattedMessage id="CheckoutTripForm.next" />
              </PrimaryButton>
            </div>
            {
              <Modal open={showPrefillModal} onClose={closeModal}>
                <Box sx={{ ...style }} className={css.boxClass}>
                  <div className={css.dialogHeading}>
                    <div className={css.prefillHeadingWrapper}>
                      <FormattedMessage
                        id="CheckoutDetailsForm.prefillTitle"
                        values={{
                          b: chunks => <strong>{chunks}</strong>,
                        }}
                      />
                    </div>
                    <div className={css.packageInfoWrapper}>
                      <div className={css.packageTitleWrapper}>
                        <div className={css.packageTitle}>Request Summary</div>
                      </div>
                      <div className={css.packageDetailsWrapper}>
                        {tripName && (
                          <div className={css.summaryWrapper}>
                            <span className={css.summaryName}>
                              <FormattedMessage id="TripSummary.what" />
                            </span>
                            <div className={css.summaryDetails}>
                              <FormattedMessage
                                id="TripSummary.budget"
                                values={{
                                  tripName,
                                  tripType,
                                  budgetCurrency,
                                  currencySign,
                                  minAmount: estimatedMinBudget
                                    ? intl.formatNumber(convertMoneyTypeToNumber(estimatedMinBudget))
                                    : 'USD 0.00',
                                  maxAmount: estimatedMaxBudget
                                    ? intl.formatNumber(convertMoneyTypeToNumber(estimatedMaxBudget))
                                    : 'USD 0.00',
                                }}
                              />
                            </div>
                          </div>
                        )}
                        {tripLocation?.search && (
                          <div className={css.summaryWrapper}>
                            <span className={css.summaryName}>
                              <FormattedMessage id="TripSummary.where" />
                            </span>
                            <div className={css.summaryDetails}>
                              <FormattedMessage
                                id="TripSummary.tripLocation"
                                values={{
                                  tripLocation: tripLocation?.search,
                                  originLocation: originLocation?.search,
                                  precision,
                                  br: <br />,
                                }}
                              />
                            </div>
                          </div>
                        )}
                        {targetStartDate && targetEndDate && (
                          <div className={css.summaryWrapper}>
                            <span className={css.summaryName}>
                              <FormattedMessage id="TripSummary.when" />
                            </span>
                            <div className={css.summaryDetails}>
                              <span>{`From: ${moment(new Date(targetStartDate)).format(
                                'ddd Do MMM HA'
                              )}`}</span>
                              <span>{`To: ${moment(new Date(targetEndDate)).format(
                                'ddd Do MMM HA'
                              )}`}</span>
                              <span>{`Precision: ${timePrecision}`}</span>
                            </div>
                          </div>
                        )}
                        <div className={css.summaryWrapper}>
                          <span className={css.summaryDetails}>
                            <FormattedMessage
                              id="TripSummary.inclusion"
                              values={{
                                missingInfo: sentence,
                                br: <br />,
                              }}
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className={css.buttonsWrapper}>
                      <SecondaryButton onClick={closeModal}>No Thanks</SecondaryButton>
                      <PrimaryButton
                        onClick={() => {
                          setShowPrefillModal(false);
                          
                          const lastOrderDetails = {
                            values: tripDetails
                          }
                          onChange(lastOrderDetails);
                        }}
                      >
                        Pre-Fill Details
                      </PrimaryButton>
                    </div>
                  </div>
                </Box>
              </Modal>
            }
          </Form>
        );
      }}
    />
  )
});

CheckoutTripFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  submitButtonWrapperClassName: null,
  inProgress: false,
};

CheckoutTripFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  submitButtonWrapperClassName: string,

  inProgress: bool,

  // from injectIntl
  intl: intlShape.isRequired,
};

const CheckoutTripForm = CheckoutTripFormComponent;

CheckoutTripForm.displayName = 'CheckoutTripForm';

export default CheckoutTripForm;
