import { ensureTransaction } from './data';
import moment from 'moment';

/**
 * Transitions
 *
 * These strings must sync with values defined in Flex API,
 * since transaction objects given by API contain info about last transitions.
 * All the actions in API side happen in transitions,
 * so we need to understand what those strings mean.
 */

// When a customer makes a booking to a listing, a transaction is
// created with the initial request-payment transition.
// At this transition a PaymentIntent is created by Marketplace API.
// After this transition, the actual payment must be made on client-side directly to Stripe.
export const TRANSITION_REQUEST_PAYMENT = 'transition/request-payment';

// A customer can also initiate a transaction with an enquiry, and
// then transition that with a request.
export const TRANSITION_ENQUIRE = 'transition/enquire';
export const TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY = 'transition/request-payment-after-enquiry';

export const TRANSITION_SET_PRICE = 'transition/set-price';
export const TRANSITION_UPDATE_PRICE = 'transition/update-price'
export const TRANSITION_EXPIRE_ENQUIRY = 'transition/expire-enquiry';
export const TRANSITION_EXPIRE_ENQUIRY_2 = 'transition/expire-enquiry-2';
export const TRANSITION_CANCEL_ENQUIRY_BY_CUSTOMER = 'transition/cancel-enquiry-by-customer';
export const TRANSITION_CANCEL_ENQUIRY_BY_PROVIDER = 'transition/cancel-enquiry-by-provider';
export const TRANSITION_CANCEL_ENQUIRY_BY_CUSTOMER_2 = 'transition/cancel-enquiry-by-customer-2';
export const TRANSITION_CANCEL_ENQUIRY_BY_PROVIDER_2 = 'transition/cancel-enquiry-by-provider-2';

// Stripe SDK might need to ask 3D security from customer, in a separate front-end step.
// Therefore we need to make another transition to Marketplace API,
// to tell that the payment is confirmed.
export const TRANSITION_CONFIRM_PAYMENT = 'transition/confirm-payment';

// In case of hitpay we are confriming status of payment from backend when we receive webhook response from hitpay server
export const TRANSITION_CONFIRM_PAYMENT_BY_OPERATOR = 'transition/confirm-payment-by-operator';

// If the payment is not confirmed in the time limit set in transaction process (by default 15min)
// the transaction will expire automatically.
export const TRANSITION_EXPIRE_PAYMENT = 'transition/expire-payment';

// When the provider accepts or declines a transaction from the
// SalePage, it is transitioned with the accept or decline transition.
export const TRANSITION_PAYMENT_CAPTURE_FAIL = 'transition/payment-capture-fail'
export const TRANSITION_ACCEPT = 'transition/accept';
export const TRANSITION_DECLINE = 'transition/decline';

// Accepted transaction before it can be completed, switch into
// delivered state automatically when after booking end time
export const TRANSITION_SET_DELIVERED = 'transition/set-delivered';
export const TRANSITION_OP_SET_DELIVERED = 'transition/op-set-delivered';
export const TRANSITION_REQUEST_EXTEND_DELIVERY_PERIOD =
  'transition/request-extend-delivery-period';
export const TRANSITION_OP_REQUEST_EXTEND_DELIVERY_PERIOD =
  'transition/op-request-extend-delivery-period';
export const TRANSITION_ACCEPT_EXTEND_DELIVERY_PERIOD = 'transition/accept-extend-delivery-period';
export const TRANSITION_OP_ACCEPT_EXTEND_DELIVERY_PERIOD =
  'transition/op-accept-extend-delivery-period';
export const TRANSITION_DECLINE_EXTEND_DELIVERY_PERIOD =
  'transition/decline-extend-delivery-period';
export const TRANSITION_OP_DECLINE_EXTEND_DELIVERY_PERIOD =
  'transition/op-decline-extend-delivery-period';
export const TRANSITION_AUTO_REQUEST_EXTEND_DELIVERY_PERIOD =
  'transition/auto-request-extend-delivery-period';

// The backend automatically expire the transaction.
export const TRANSITION_EXPIRE_REQUEST_PERIOD = 'transition/expire-request-period';

// Admin can also cancel the transition.
export const TRANSITION_CANCEL = 'transition/cancel';
export const TRANSITION_OP_CANCEL_ORDER = 'transition/op-cancel-order';
export const TRANSITION_OP_CANCEL_ORDER_2 = 'transition/op-cancel-order-2';
export const TRANSITION_CANCEL_READY_TO_COMPLETE = 'transition/cancel-ready-to-complete';

// The backend will mark the transaction completed.
export const TRANSITION_COMPLETE = 'transition/complete';
export const TRANSITION_OP_COMPLETE = 'transition/op-complete';

export const TRANSITION_REQUEST_CHANGES = 'transition/request-changes';
export const TRANSITION_OP_REQUEST_CHANGES = 'transition/op-request-changes';
export const TRANSITION_MARK_DELIVERED_CHANGES = 'transition/mark-delivered-changes';
export const TRANSITION_OP_MARK_DELIVERED_CHANGES = 'transition/op-mark-delivered-changes';

// Manual variation of complete transition.
export const TRANSITION_COMPLETE_MANUAL = 'transition/complete-manual';

export const TRANSITION_RESCHEDULE_BY_CUSTOMER = 'transition/reschedule-by-customer';
export const TRANSITION_RESCHEDULE_BY_PROVIDER = 'transition/reschedule-by-provider';
export const TRANSITION_RESCHEDULE_BY_PROVIDER_AFTER_READY_TO_COMPLETE =
  'transition/reschedule-by-provider-after-ready-to-complete-state';

// Reviews are given through transaction transitions. Review 1 can be
// by provider or customer, and review 2 will be the other party of
// the transaction.
export const TRANSITION_REVIEW_1_BY_PROVIDER = 'transition/review-1-by-provider';
export const TRANSITION_OP_REVIEW_1_BY_PROVIDER = 'transition/op-review-1-by-provider';
export const TRANSITION_REVIEW_2_BY_PROVIDER = 'transition/review-2-by-provider';
export const TRANSITION_OP_REVIEW_2_BY_PROVIDER = 'transition/op-review-2-by-provider';
export const TRANSITION_REVIEW_1_BY_CUSTOMER = 'transition/review-1-by-customer';
export const TRANSITION_OP_REVIEW_1_BY_CUSTOMER = 'transition/op-review-1-by-customer';
export const TRANSITION_REVIEW_2_BY_CUSTOMER = 'transition/review-2-by-customer';
export const TRANSITION_OP_REVIEW_2_BY_CUSTOMER = 'transition/op-review-2-by-customer';
export const TRANSITION_SKIP_1_BY_PROVIDER = 'transition/skip-1-by-provider';
export const TRANSITION_SKIP_2_BY_PROVIDER = 'transition/skip-2-by-provider';
export const TRANSITION_SKIP_3_BY_PROVIDER = 'transition/skip-3-by-provider';
export const TRANSITION_SKIP_1_BY_CUSTOMER = 'transition/skip-1-by-customer';
export const TRANSITION_SKIP_2_BY_CUSTOMER = 'transition/skip-2-by-customer';
export const TRANSITION_SKIP_3_BY_CUSTOMER = 'transition/skip-3-by-customer';
export const TRANSITION_EXPIRE_CUSTOMER_REVIEW_PERIOD = 'transition/expire-customer-review-period';
export const TRANSITION_EXPIRE_PROVIDER_REVIEW_PERIOD = 'transition/expire-provider-review-period';
export const TRANSITION_EXPIRE_REVIEW_PERIOD = 'transition/expire-review-period';
export const TRANSITION_EXPIRE_PROVIDER_SKIP_PERIOD = 'transition/expire-provider-skip-period';
export const TRANSITION_EXPIRE_CUSTOMER_SKIP_PERIOD = 'transition/expire-customer-skip-period';
export const TRANSITION_CHARGE_CUSTOMER = 'transition/charge-customer';

export const TRANSITION_EXPIRED_FROM_ACCEPTED_1 = 'transition/expired-from-accepted-1';
export const TRANSITION_EXPIRED_FROM_DELIVERED_1 = 'transition/expired-from-delivered-1';

/**
 * Actors
 *
 * There are 4 different actors that might initiate transitions:
 */

// Roles of actors that perform transaction transitions
export const TX_TRANSITION_ACTOR_CUSTOMER = 'customer';
export const TX_TRANSITION_ACTOR_PROVIDER = 'provider';
export const TX_TRANSITION_ACTOR_SYSTEM = 'system';
export const TX_TRANSITION_ACTOR_OPERATOR = 'operator';

export const TX_TRANSITION_ACTORS = [
  TX_TRANSITION_ACTOR_CUSTOMER,
  TX_TRANSITION_ACTOR_PROVIDER,
  TX_TRANSITION_ACTOR_SYSTEM,
  TX_TRANSITION_ACTOR_OPERATOR,
];

/**
 * States
 *
 * These constants are only for making it clear how transitions work together.
 * You should not use these constants outside of this file.
 *
 * Note: these states are not in sync with states used transaction process definitions
 *       in Marketplace API. Only last transitions are passed along transaction object.
 */
const STATE_INITIAL = 'initial';
const STATE_ENQUIRY = 'enquiry';
const STATE_PRICE_OFFERED = 'price-offered';
const STATE_PENDING_PAYMENT = 'pending-payment';
const STATE_PAYMENT_EXPIRED = 'payment-expired';
const STATE_WORK_STARTED = 'work-started';
const STATE_PREAUTHORIZED = 'preauthorized';
const STATE_DECLINED = 'declined';
const STATE_PAYMENT_CAPTURING = 'payment-capturing';
const STATE_PAYMENT_CAPTURE_FAILED = 'payment-capture-failed';
const STATE_ACCEPTED = 'accepted';
const STATE_RESCHEDULED = 'accepted';
const STATE_RESCHEDULED_AFTER_READY_TO_COMPLETE = 'ready-to-complete';
const STATE_CANCELED = 'canceled';
const STATE_DELIVERED = 'delivered';
const STATE_REVIEWED = 'reviewed';
const STATE_COMPLETED = 'completed';
const STATE_REVIEWED_BY_CUSTOMER = 'reviewed-by-customer';
const STATE_REVIEWED_BY_PROVIDER = 'reviewed-by-provider';
const STATE_SKIPPED_BY_CUSTOMER = 'skipped-by-customer';
const STATE_SKIPPED_BY_PROVIDER = 'skipped-by-provider';

export const filterState = {
  ALL: 'all',
  ENQUIRY: 'enquiry',
  WORKSTARTED: 'work-started',
  DELIVERED: 'delivered',
  CANCELED: 'canceled',
}

const workflowStatus = {
  STARTED: 'started',
  REVEALED: 'revealed',
  DELIVERED: 'delivered',
}

/**
 * Description of transaction process
 *
 * You should keep this in sync with transaction process defined in Marketplace API
 *
 * Note: we don't use yet any state machine library,
 *       but this description format is following Xstate (FSM library)
 *       https://xstate.js.org/docs/
 */
const stateDescription = {
  // id is defined only to support Xstate format.
  // However if you have multiple transaction processes defined,
  // it is best to keep them in sync with transaction process aliases.
  id: 'flex-product-default-process/release-2',

  // This 'initial' state is a starting point for new transaction
  initial: STATE_INITIAL,

  // States
  states: {
    [STATE_INITIAL]: {
      on: {
        [TRANSITION_ENQUIRE]: STATE_ENQUIRY,
      },
    },

    [STATE_ENQUIRY]: {
      on: {
        [TRANSITION_SET_PRICE]: STATE_PRICE_OFFERED,
        [TRANSITION_CANCEL_ENQUIRY_BY_CUSTOMER]: STATE_CANCELED,
        [TRANSITION_CANCEL_ENQUIRY_BY_PROVIDER]: STATE_CANCELED,
        [TRANSITION_EXPIRE_ENQUIRY]: STATE_CANCELED,
      },
    },

    [STATE_CANCELED]: {},
    [STATE_PRICE_OFFERED]: {
      on: {
        [TRANSITION_UPDATE_PRICE]: STATE_PRICE_OFFERED,
        [TRANSITION_REQUEST_PAYMENT]: STATE_PENDING_PAYMENT,
        [TRANSITION_EXPIRE_ENQUIRY_2]: STATE_CANCELED,
        [TRANSITION_CANCEL_ENQUIRY_BY_CUSTOMER]: STATE_CANCELED,
        [TRANSITION_CANCEL_ENQUIRY_BY_PROVIDER]: STATE_CANCELED,
      },
    },
    [STATE_PENDING_PAYMENT]: {
      on: {
        [TRANSITION_EXPIRE_PAYMENT]: STATE_CANCELED,
        [TRANSITION_CONFIRM_PAYMENT]: STATE_WORK_STARTED,
      },
    },

    [STATE_WORK_STARTED]: {
      on: {
        [TRANSITION_OP_REVIEW_1_BY_CUSTOMER]: STATE_REVIEWED_BY_CUSTOMER,
        [TRANSITION_OP_REVIEW_1_BY_PROVIDER]: STATE_REVIEWED_BY_PROVIDER,
        [TRANSITION_OP_CANCEL_ORDER]: STATE_CANCELED,
      },
    },

    [STATE_REVIEWED_BY_CUSTOMER]: {
      on: {
        [TRANSITION_OP_REVIEW_2_BY_PROVIDER]: STATE_REVIEWED,
        [TRANSITION_EXPIRE_PROVIDER_REVIEW_PERIOD]: STATE_REVIEWED,
      },
    },
    [STATE_REVIEWED_BY_PROVIDER]: {
      on: {
        [TRANSITION_OP_REVIEW_2_BY_CUSTOMER]: STATE_REVIEWED,
        [TRANSITION_EXPIRE_CUSTOMER_REVIEW_PERIOD]: STATE_REVIEWED,
      },
    },
    [STATE_REVIEWED]: {
      on: {
        [TRANSITION_OP_CANCEL_ORDER_2]: STATE_CANCELED,
      },
    },
  },
};

// Note: currently we assume that state description doesn't contain nested states.
const statesFromStateDescription = description => description.states || {};

// Get all the transitions from states object in an array
const getTransitions = states => {
  const stateNames = Object.keys(states);

  const transitionsReducer = (transitionArray, name) => {
    const stateTransitions = states[name] && states[name].on;
    const transitionKeys = stateTransitions ? Object.keys(stateTransitions) : [];
    return [
      ...transitionArray,
      ...transitionKeys.map(key => ({ key, value: stateTransitions[key] })),
    ];
  };

  return stateNames.reduce(transitionsReducer, []);
};

// This is a list of all the transitions that this app should be able to handle.
export const TRANSITIONS = getTransitions(statesFromStateDescription(stateDescription)).map(
  t => t.key
);

// This function returns a function that has given stateDesc in scope chain.
const getTransitionsToStateFn = stateDesc => state =>
  getTransitions(statesFromStateDescription(stateDesc))
    .filter(t => t.value === state)
    .map(t => t.key);

// Get all the transitions that lead to specified state.
const getTransitionsToState = getTransitionsToStateFn(stateDescription);

// This is needed to fetch transactions that need response from provider.
// I.e. transactions which provider needs to accept or decline
export const transitionsToRequested = getTransitionsToState(STATE_PREAUTHORIZED);

/**
 * Helper functions to figure out if transaction is in a specific state.
 * State is based on lastTransition given by transaction object and state description.
 */

const txLastTransition = tx => ensureTransaction(tx).attributes.lastTransition;

export const txIsEnquired = tx =>
  getTransitionsToState(STATE_ENQUIRY).includes(txLastTransition(tx));

export const txIsPriceOffered = tx =>
  getTransitionsToState(STATE_PRICE_OFFERED).includes(txLastTransition(tx))
  || getTransitionsToState(STATE_PENDING_PAYMENT).includes(txLastTransition(tx));

export const txIsWorkStarted = tx =>
  (getTransitionsToState(STATE_WORK_STARTED).includes(txLastTransition(tx))
    || getTransitionsToState(STATE_REVIEWED_BY_PROVIDER).includes(txLastTransition(tx))
    || getTransitionsToState(STATE_REVIEWED_BY_CUSTOMER).includes(txLastTransition(tx))
    || getTransitionsToState(STATE_REVIEWED).includes(txLastTransition(tx)))
  && [
    undefined,
    workflowStatus.STARTED,
    workflowStatus.REVEALED,
  ].includes(tx.attributes.metadata?.workflowStatus);

export const txIsDelivered = tx =>
  (getTransitionsToState(STATE_WORK_STARTED).includes(txLastTransition(tx))
    || getTransitionsToState(STATE_REVIEWED_BY_PROVIDER).includes(txLastTransition(tx))
    || getTransitionsToState(STATE_REVIEWED_BY_CUSTOMER).includes(txLastTransition(tx))
    || getTransitionsToState(STATE_REVIEWED).includes(txLastTransition(tx)))
  && tx.attributes.metadata?.workflowStatus === workflowStatus.DELIVERED;

export const txIsPaymentPending = tx =>
  getTransitionsToState(STATE_PENDING_PAYMENT).includes(txLastTransition(tx));

export const txIsPaymentExpired = (tx) => (
 TRANSITION_EXPIRE_PAYMENT === txLastTransition(tx)
);

// Note: state name used in Marketplace API docs (and here) is actually preauthorized
// However, word "requested" is used in many places so that we decided to keep it.
export const txIsRequested = tx =>
  getTransitionsToState(STATE_PREAUTHORIZED).includes(txLastTransition(tx));

export const txIsAccepted = tx => {
  return getTransitionsToState(STATE_ACCEPTED).includes(txLastTransition(tx));
};

export const txIsRequestAccepted = tx =>
  getTransitionsToState(STATE_PAYMENT_CAPTURING).includes(txLastTransition(tx));

export const txIsExtendRequested = tx => {
  return txLastTransition(tx) === TRANSITION_REQUEST_EXTEND_DELIVERY_PERIOD;
};

export const txIsRescheduled = tx => {
  return [
    TRANSITION_RESCHEDULE_BY_CUSTOMER,
    TRANSITION_RESCHEDULE_BY_PROVIDER,
    TRANSITION_RESCHEDULE_BY_PROVIDER_AFTER_READY_TO_COMPLETE,
  ].includes(txLastTransition(tx));
};

export const txIsRescheduledByCustomer = tx => {
  return [TRANSITION_RESCHEDULE_BY_CUSTOMER].includes(txLastTransition(tx));
};

export const txIsRescheduledByProvider = tx => {
  return [
    TRANSITION_RESCHEDULE_BY_PROVIDER,
    TRANSITION_RESCHEDULE_BY_PROVIDER_AFTER_READY_TO_COMPLETE,
  ].includes(txLastTransition(tx));
};

export const txIsDeclined = tx =>
  getTransitionsToState(STATE_DECLINED).includes(txLastTransition(tx));

export const txIsPaymentCaptureFailed = tx =>
  getTransitionsToState(STATE_PAYMENT_CAPTURE_FAILED).includes(txLastTransition(tx));

export const txIsCanceled = tx => {
  return getTransitionsToState(STATE_CANCELED).includes(txLastTransition(tx));
};

export const txIsCompleted = tx =>
  getTransitionsToState(STATE_COMPLETED).includes(txLastTransition(tx));

// export const txIsCompleted = tx =>
//   [TRANSITION_COMPLETE, TRANSITION_REVIEW_1_BY_CUSTOMER, TRANSITION_REVIEW_1_BY_PROVIDER, TRANSITION_EXPIRE_REVIEW_PERIOD].includes(txLastTransition(tx));

export const txIsPending = tx => txLastTransition(tx) === TRANSITION_REQUEST_CHANGES;

export const txReviewsStarted = tx =>
  getTransitionsToState(STATE_REVIEWED_BY_CUSTOMER).includes(txLastTransition(tx)) ||
  getTransitionsToState(STATE_REVIEWED_BY_PROVIDER).includes(txLastTransition(tx)) ||
  getTransitionsToState(STATE_REVIEWED).includes(txLastTransition(tx));

const firstReviewTransitions = [
  ...getTransitionsToState(STATE_REVIEWED_BY_CUSTOMER),
  ...getTransitionsToState(STATE_REVIEWED_BY_PROVIDER),
];
export const txIsInFirstReview = tx => firstReviewTransitions.includes(txLastTransition(tx));

export const txIsInFirstReviewBy = (tx, isCustomer) =>
  isCustomer
    ? getTransitionsToState(STATE_REVIEWED_BY_CUSTOMER).includes(txLastTransition(tx))
    : getTransitionsToState(STATE_REVIEWED_BY_PROVIDER).includes(txLastTransition(tx));

export const txIsReviewed = tx =>
  getTransitionsToState(STATE_REVIEWED).includes(txLastTransition(tx));

/**
 * Helper functions to figure out if transaction has passed a given state.
 * This is based on transitions history given by transaction object.
 */

const txTransitions = tx => ensureTransaction(tx).attributes.transitions || [];
const hasPassedTransition = (transitionName, tx) =>
  !!txTransitions(tx).find(t => t.transition === transitionName);

const hasPassedStateFn = state => tx =>
  getTransitionsToState(state).filter(t => hasPassedTransition(t, tx)).length > 0;

export const txHasBeenAccepted = hasPassedStateFn(STATE_ACCEPTED);
export const txHasBeenDelivered = hasPassedStateFn(STATE_DELIVERED);

// Helper function to check if the transaction has passed a certain state
export const txHasPassedPaymentPending = hasPassedStateFn(STATE_PENDING_PAYMENT);

/**
 * Other transaction related utility functions
 */

export const transitionIsReviewed = transition =>
  getTransitionsToState(STATE_REVIEWED).includes(transition);

export const transitionIsFirstReviewedBy = (transition, isCustomer) =>
  isCustomer
    ? getTransitionsToState(STATE_REVIEWED_BY_CUSTOMER).includes(transition)
    : getTransitionsToState(STATE_REVIEWED_BY_PROVIDER).includes(transition);

export const getReview1Transition = isCustomer =>
  isCustomer ? TRANSITION_REVIEW_1_BY_CUSTOMER : TRANSITION_REVIEW_1_BY_PROVIDER;

export const getReview2Transition = isCustomer =>
  isCustomer ? TRANSITION_REVIEW_2_BY_CUSTOMER : TRANSITION_REVIEW_2_BY_PROVIDER;

export const getSkip1Transition = isCustomer =>
  isCustomer ? TRANSITION_SKIP_1_BY_CUSTOMER : TRANSITION_SKIP_1_BY_PROVIDER;

export const getSkip2Transition = isCustomer =>
  isCustomer ? TRANSITION_SKIP_2_BY_CUSTOMER : TRANSITION_SKIP_2_BY_PROVIDER;

export const getSkip3Transition = isCustomer =>
  isCustomer ? TRANSITION_SKIP_3_BY_CUSTOMER : TRANSITION_SKIP_3_BY_PROVIDER;

// Check if a transition is the kind that should be rendered
// when showing transition history (e.g. ActivityFeed)
// The first transition and most of the expiration transitions made by system are not relevant
export const isRelevantPastTransition = transition => {
  return [
    TRANSITION_ENQUIRE,
    TRANSITION_SET_PRICE,
    TRANSITION_CANCEL_ENQUIRY_BY_CUSTOMER,
    TRANSITION_CANCEL_ENQUIRY_BY_PROVIDER,
    TRANSITION_EXPIRE_ENQUIRY,
    TRANSITION_UPDATE_PRICE,
    TRANSITION_EXPIRE_ENQUIRY_2,
    TRANSITION_REQUEST_PAYMENT,
    TRANSITION_EXPIRE_PAYMENT,
    TRANSITION_CONFIRM_PAYMENT,
    TRANSITION_OP_CANCEL_ORDER,
    TRANSITION_OP_CANCEL_ORDER_2,
    TRANSITION_OP_REVIEW_1_BY_CUSTOMER,
    TRANSITION_OP_REVIEW_1_BY_PROVIDER,
    TRANSITION_OP_REVIEW_2_BY_CUSTOMER,
    TRANSITION_OP_REVIEW_2_BY_PROVIDER,
    TRANSITION_EXPIRE_PROVIDER_REVIEW_PERIOD,
    TRANSITION_EXPIRE_CUSTOMER_REVIEW_PERIOD,
  ].includes(transition);
};

export const isCustomerReview = transition => {
  return [
    TRANSITION_REVIEW_1_BY_CUSTOMER,
    TRANSITION_OP_REVIEW_1_BY_CUSTOMER,
    TRANSITION_REVIEW_2_BY_CUSTOMER,
    TRANSITION_OP_REVIEW_2_BY_CUSTOMER,
  ].includes(transition);
};

export const isProviderReview = transition => {
  return [
    TRANSITION_REVIEW_1_BY_PROVIDER,
    TRANSITION_OP_REVIEW_1_BY_PROVIDER,
    TRANSITION_REVIEW_2_BY_PROVIDER,
    TRANSITION_OP_REVIEW_2_BY_PROVIDER,
  ].includes(transition);
};

export const getUserTxRole = (currentUserId, transaction) => {
  const tx = ensureTransaction(transaction);
  const customer = tx.customer;
  if (currentUserId && currentUserId.uuid && tx.id && customer.id) {
    // user can be either customer or provider
    return currentUserId.uuid === customer.id.uuid
      ? TX_TRANSITION_ACTOR_CUSTOMER
      : TX_TRANSITION_ACTOR_PROVIDER;
  } else {
    throw new Error(`Parameters for "userIsCustomer" function were wrong.
      currentUserId: ${currentUserId}, transaction: ${transaction}`);
  }
};

export const txRoleIsProvider = userRole => userRole === TX_TRANSITION_ACTOR_PROVIDER;
export const txRoleIsCustomer = userRole => userRole === TX_TRANSITION_ACTOR_CUSTOMER;

export const COMPLETE_TRANSITIONS = [
  TRANSITION_CONFIRM_PAYMENT,
  TRANSITION_OP_REVIEW_1_BY_PROVIDER,
  TRANSITION_OP_REVIEW_1_BY_CUSTOMER,
  TRANSITION_OP_REVIEW_2_BY_PROVIDER,
  TRANSITION_OP_REVIEW_2_BY_CUSTOMER,
  TRANSITION_EXPIRE_CUSTOMER_REVIEW_PERIOD,
  TRANSITION_EXPIRE_PROVIDER_REVIEW_PERIOD,
];

/**
 * Each case invovles legacy transitions for the old version of transaction process model as well alongside with the comment 'legacy'
 * 
 * Note that `transition/confirm-payment` is used in both old and new versions of transaction process model though for differnt scenarios
 * So 'transition/confirm-payment' in the previous model maps into 'transition/enquire' in a new model
 * 
 * Excludes the old transactions in which last transition is transition/confirm-payment 
 * in order to avoid the complexity of backwards-compatibility 
 * as transition/confirm-payment transition drives the transaction to the different concent state between release-1 and release-2 models
 * 
 * PS: it won't be problematic because all the old transactions have been completed or expired in dev environment
 * and there are few transactions in live environment
 */

export const getTransitionsByState = state => {
  switch (state) {
    case filterState.ENQUIRY:
      return [
        TRANSITION_ENQUIRE,
        TRANSITION_SET_PRICE,
        TRANSITION_UPDATE_PRICE,
        // for processAlias release-1
        // TRANSITION_CONFIRM_PAYMENT,
        TRANSITION_CONFIRM_PAYMENT_BY_OPERATOR,
        TRANSITION_REQUEST_PAYMENT,
        TRANSITION_CHARGE_CUSTOMER,
      ];
    case filterState.WORKSTARTED:
      return [
        TRANSITION_CONFIRM_PAYMENT,
        TRANSITION_OP_REVIEW_1_BY_CUSTOMER,
        TRANSITION_OP_REVIEW_1_BY_PROVIDER,
        TRANSITION_OP_REVIEW_2_BY_CUSTOMER,
        TRANSITION_OP_REVIEW_2_BY_PROVIDER,
        TRANSITION_EXPIRE_CUSTOMER_REVIEW_PERIOD,
        TRANSITION_EXPIRE_PROVIDER_REVIEW_PERIOD,
        // for processAlias release-1
        TRANSITION_ACCEPT,
        TRANSITION_REQUEST_EXTEND_DELIVERY_PERIOD,
        TRANSITION_OP_REQUEST_EXTEND_DELIVERY_PERIOD,
        TRANSITION_ACCEPT_EXTEND_DELIVERY_PERIOD,
        TRANSITION_OP_ACCEPT_EXTEND_DELIVERY_PERIOD,
        TRANSITION_AUTO_REQUEST_EXTEND_DELIVERY_PERIOD,
        TRANSITION_RESCHEDULE_BY_PROVIDER,
        TRANSITION_RESCHEDULE_BY_CUSTOMER,
        TRANSITION_SET_DELIVERED,
        TRANSITION_OP_SET_DELIVERED,
        TRANSITION_RESCHEDULE_BY_PROVIDER_AFTER_READY_TO_COMPLETE,
        TRANSITION_MARK_DELIVERED_CHANGES,
        TRANSITION_OP_MARK_DELIVERED_CHANGES,
        TRANSITION_REQUEST_CHANGES,
        TRANSITION_OP_REQUEST_CHANGES,
        TRANSITION_EXPIRE_REVIEW_PERIOD,
        TRANSITION_REVIEW_1_BY_PROVIDER,
        TRANSITION_REVIEW_2_BY_PROVIDER,
        TRANSITION_REVIEW_1_BY_CUSTOMER,
        TRANSITION_REVIEW_2_BY_CUSTOMER,
      ]
    case filterState.DELIVERED:
      return [
        TRANSITION_CONFIRM_PAYMENT,
        TRANSITION_OP_REVIEW_1_BY_CUSTOMER,
        TRANSITION_OP_REVIEW_1_BY_PROVIDER,
        TRANSITION_OP_REVIEW_2_BY_CUSTOMER,
        TRANSITION_OP_REVIEW_2_BY_PROVIDER,
        TRANSITION_EXPIRE_CUSTOMER_REVIEW_PERIOD,
        TRANSITION_EXPIRE_PROVIDER_REVIEW_PERIOD,
        // for processAlias release-1
        TRANSITION_COMPLETE,
        TRANSITION_OP_COMPLETE,
        TRANSITION_COMPLETE_MANUAL,
        TRANSITION_EXPIRED_FROM_DELIVERED_1,
        TRANSITION_REVIEW_1_BY_PROVIDER,
        TRANSITION_REVIEW_2_BY_PROVIDER,
        TRANSITION_REVIEW_1_BY_CUSTOMER,
        TRANSITION_REVIEW_2_BY_CUSTOMER,
        TRANSITION_EXPIRE_REVIEW_PERIOD,
      ];
    case filterState.CANCELED:
      return [
        TRANSITION_EXPIRE_ENQUIRY,
        TRANSITION_CANCEL_ENQUIRY_BY_CUSTOMER,
        TRANSITION_CANCEL_ENQUIRY_BY_PROVIDER,
        TRANSITION_EXPIRE_ENQUIRY_2,
        TRANSITION_CANCEL_ENQUIRY_BY_CUSTOMER_2,
        TRANSITION_CANCEL_ENQUIRY_BY_PROVIDER_2,
        TRANSITION_EXPIRE_PAYMENT,
        TRANSITION_OP_CANCEL_ORDER,
        TRANSITION_OP_CANCEL_ORDER_2,
        // for processAlias release-1
        TRANSITION_PAYMENT_CAPTURE_FAIL,
        TRANSITION_DECLINE,
        TRANSITION_EXPIRE_REQUEST_PERIOD,
        TRANSITION_CANCEL,
        TRANSITION_CANCEL_READY_TO_COMPLETE,
        TRANSITION_DECLINE_EXTEND_DELIVERY_PERIOD,
        TRANSITION_OP_DECLINE_EXTEND_DELIVERY_PERIOD,
        TRANSITION_EXPIRED_FROM_ACCEPTED_1,
      ];
    case 'all':
      return TRANSITIONS;
    default:
      return TRANSITIONS;
  }
};

export const wasTransactionCreatedBeforeTimestamp = (tx, beforeTime) => {
  const firstTransitionCreatedAt = tx.attributes.transitions[0].createdAt;
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const timeStampPoint = moment(beforeTime)
    .tz(timeZone)
    .toDate();
  return moment(firstTransitionCreatedAt).isBefore(timeStampPoint);
};

export const filterTxByListing = (listingId, txs) => {
  return txs.filter(tx => tx.listing.id.uuid === listingId.uuid);
};

// Check if the given transition is privileged.
//
// Privileged transitions need to be handled from a secure context,
// i.e. the backend. This helper is used to check if the transition
// should go through the local API endpoints, or if using JS SDK is
// enough.
export const isPrivileged = transition => {
  return [
    TRANSITION_ENQUIRE,
    TRANSITION_SET_PRICE,
    TRANSITION_REQUEST_PAYMENT,
    TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY,
    TRANSITION_CHARGE_CUSTOMER,
    TRANSITION_CONFIRM_PAYMENT,
  ].includes(transition);
};
